import { createContext } from 'react';

export const ELSI_API_URL = 'https://api.auroraelectronics.ru';
export const ELSI_CABINET_URL = 'https://cabinet.auroraelectronics.ru';
export const ELSI_STATIC_URL = 'https://cdn.auroraelectronics.ru';
export const ELSI_WEBSOCKET_URL = 'wss://ws.auroraelectronics.ru';
export const ELSI_SHOP_URL = "FRONTEND_SHOP_URL";

export const MAIN_PAGE_TAB_FIRST = 'MAIN_PAGE_TAB_FIRST';
export const MAIN_PAGE_TAB_SECOND = 'MAIN_PAGE_TAB_SECOND';
export const MAIN_PAGE_TAB_THIRD = 'MAIN_PAGE_TAB_THIRD';
export const MAIN_PAGE_TAB_FOURTH = 'MAIN_PAGE_TAB_FOURTH';

export const ADD_PRODUCT_TO_ORDER_POPUP_KEY = 'add-product-to-order';
export const SEND_PRICES_REQUEST = 'send-prices-request';

export const EMPTY_IMAGE_SRC = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

export const MAIN_PAGE_TAB_LIST = [
    {
        number: '01',
        text: "Гарантия\nот 12 месяцев",
        content:'Каталог более 12 млн компонентов',
        img: '/pictures/background-ready-100.jpg',
    }, 
    {
        number: '02',
        text: '>12 млн. компонентов',
        content:'Каталог более 12 млн компонентов',
        img: '/pictures/background-ready-100.jpg',
    }, 
    {
        number: '03',
        text: "Доставка\nот 3-х дней",
        content:'Каталог более 12 млн компонентов',
        img: '/pictures/background-ready-100.jpg',
    },
    {
        number: '04',
        text: `Гарантия\nот 12 месяцев`,
        content:'Каталог более 12 млн компонентов',
        img: '/pictures/background-ready-100.jpg',
    }, 
];

export const MAIN_PAGE = '/';
export const PRODUCTS_PAGE = '/products';
export const CATEGORY_PAGE = '/category';
export const PRODUCT_PAGE = '/product';
export const COMPANY_PAGE = '/company';
export const DELIVERY_PAGE = '/delivery';
export const CONTACTS_PAGE = '/contacts';
export const MANUFACTURERS_PAGE = '/manufacturers';
export const TECHNICAL_DOCUMENTS_PAGE = '/tech-docs';
export const USER_AGREEMENT_PAGE = '/user-agreement';

export const errorCallback = (error) => {
    if (error.name === 'AbortError') {
        return null;
    } else {
        console.log(error);
    }
};

export const succesCallbackEmpty = (response) => {
};

export const makeUrl = (params) => {
    const result = `${ELSI_API_URL}` + params.join('');
    return result;
};

export const findNotEmptyImageName = (array) => {
    let result = '';
    if(array&&array.length) {
        array.forEach(it => it.name?result=it.name:'');
    }
    return result;
};

export const NavigationBlockContext = createContext();