import React, { useState, useRef, useEffect } from 'react';
import { Suspense } from "react";
import { useRouter } from 'next/router';
import '../../scss/app.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { NavigationBlockContext, errorCallback, makeUrl } from '../const/const';
import { CustomNextRouter } from '../services/customRouter';
import { Metrika } from '../components/Metrika';
import { hintsService } from '../components/sharedComponents/hintsService';
import Head from 'next/head';
import { MAKE_PRODUCT_REQUEST, getFetchRequest } from '../store/requests';

const CATEGORY_ATTRIBUTE_KEY = 'Category';
const PRODUCT_PAGE_NAME = 'product';

const findCategoryValue = (product) => {
    const category = product.attributes.find(it => it.attributeKey === CATEGORY_ATTRIBUTE_KEY);
    return category ? category.attributeValue.split('\n').reverse()[0].toUpperCase() : '';
};

const createProductDescription = (product) => {
    const result = [];
    product.attributes.forEach(it => result.push(`${it.attributeKey}: ${it.attributeValue.replace(/(\r\n|\n|\r)/gm, " ")}`));
    return result.join(', ');
};

const IndexPage = (params) => {
    const [product, setProduct] = useState(null);

    useEffect(() => {
        if(params && params.id && params.path.includes(PRODUCT_PAGE_NAME)) {
            getFetchRequest(makeUrl([MAKE_PRODUCT_REQUEST, '/', params.id, '/']), (response) => setProduct(response), errorCallback);
        }
    }, []);

    if(product && product.id) {
        return (
            product ?
                <Head>
                    <title>{product.partNumber} | {product.manufacturer?.name.toUpperCase()}, {findCategoryValue(product)}</title>
                    <meta name="description" content={createProductDescription(product)} />
                </Head>
                :
                <Head/>
        );
    } else {
        return (
            <Head>
                <title>Aurora Electronics</title>
                <meta name="description" content="Аврора Электроникс — платформа электронной коммерции радиоэлектронных компонентов, комплектующих и оборудования. Миллионы товаров по низким ценам. Бесплатная доставка." />
            </Head>
        );
    }
};

export default function MyApp({ Component, pageProps }) {
    const [currentPath, setCurrentPath] = useState('');
    const [categories, setCategories] = useState('');
    const [title, setTitle] = useState('');
    const [listOfSearchOptions, setListOfSearchOptions] = useState([]);
    const router = useRouter(); 
    const [customRouterIsReady, setCustomRouterIsReady] = useState(false);
    const CustomRouter = new CustomNextRouter(router);
    const ws = useRef(null);

    const handleCloseSearchList = (e) => {
        e.stopPropagation();
        e.preventDefault();
        hintsService.clearHintsList();
    };

    useEffect(() => {
        if(router.isReady) {
            setCustomRouterIsReady(true);
        }
    }, [router.isReady]);

    if(!customRouterIsReady) {
        return null;
    }

    return (
        <>  
            <Suspense>
                <Metrika />
            </Suspense>
            <ToastContainer/>
            <IndexPage id={router.query.id} path={router.asPath}/>
            <NavigationBlockContext.Provider value={[currentPath, setCurrentPath, categories, setCategories,title, setTitle, router, CustomRouter, listOfSearchOptions, setListOfSearchOptions, ws ]}>
                <div onClick={handleCloseSearchList}>
                    <Component {...pageProps} />
                </div>
            </NavigationBlockContext.Provider>
        </>
        
    );
};