export const CHANGE_PASSWORD_REQUEST = '/api/v1/password/request/';
export const RESET_PASSWORD_REQUEST = '/api/v1/password/reset/';
export const LOGIN_REQUEST = '/api/v1/auth/login/';
export const MAKE_USER_REQUEST = '/api/v1/user/';
export const GET_CURRENT_USER = '/api/v1/user/current/';
export const CSRF_REQUEST = '/api/v1/page/csrf/';
export const MAKE_MANUFACTURER_REQUEST = '/api/v1/manufacturer/';

export const MAKE_PRODUCT_REQUEST = '/api/v1/product';
export const MAKE_CATEGORY_REQUEST = '/api/v1/category';
export const SEARCH_PRODUCT_REQUESTS = '/api/v1/product/search/';
export const MAKE_ORDER_REQUEST = '/api/v1/order/';
export const MAKE_REQUEST = '/api/v1/request/';
export const GET_STOCKS = '/api/v1/stock/';
export const CONFIGS_URL = '/api/v1/application/config/';

export const getFetchRequest = (url, succesCallback, errorCallback, withoutJSON=false) => {
    const token = localStorage.getItem('token');
    const headers = {
        "Content-Type": "application/json", 
    };
    if(token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    fetch(`${url}`, {
        credentials: 'include',
        method: 'GET',
        headers,
    }).then(response => {
        if(withoutJSON||!response.ok) {
            return response;
        } else {
            return response.json(); 
        }
    }).then(response => {
        succesCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const getDatasheetRequest = (url, succesCallback, errorCallback) => {
    fetch(`${url}`, {
        method: 'GET',
    }).then(response => {
        succesCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const postFetchRequest = (url, body, succesCallback, errorCallback, withoutJSON=false) => {
    const token = localStorage.getItem('token');
    const headers = {
        "Content-Type": "application/json", 
    };
    if(token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    fetch(`${url}`, {
        credentials: 'include',
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        if(body&&!withoutJSON&&response.ok) {
            return response.json();
        } else {
            return response;
        }
    }).then(response => {
        succesCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const postFetchRequestWithSignal = (url, body, succesCallback, errorCallback, controller, withoutJSON=false) => {
    const token = localStorage.getItem('token');
    const headers = {
        "Content-Type": "application/json", 
    };
    if(token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    fetch(`${url}`, {
        credentials: 'include',
        method: 'POST',
        signal: controller.signal,
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        if(body&&!withoutJSON&&response.ok) {
            return response.json();
        } else {
            return response;
        }
    }).then(response => {
        succesCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};