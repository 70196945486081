import { CustomRouter } from "../components/sharedComponents/helper";

export class CustomNextRouter extends CustomRouter {
    constructor(router) {
        super(router);
        this.router = router;
    }

    getParams(key) {
        const params = this.router.query;
        return params[key];
    }

    getSearchParams(key) {
        const params = this.router.query;
        return params[key];
    }

    goBack() {
        this.router.back();
    }

    needToUseCabinetStyles() {
        return false;
    }

    navigate(path) {
        if(typeof path === 'object') {
            this.router.push(path);
        } else {
            this.router.push(this.findCurrentPath(path));
        }   
    }

    findCurrentPath(path='') {
        if(path&&(path.startsWith('/product')||path.startsWith('/search')||path.startsWith('/login')||path === '/'||path.startsWith('/category'))) {
            return `${path}`;
        } else if(path==='/catalog') {
            return `/products`;
        } else {
            return `/category${path}`;
        }
    }

}